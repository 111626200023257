<template>
  <div>
    <el-page-header @back="goBack" content="主题增加页面"> </el-page-header>
    <div class="new">
      <el-form ref="tablefrom" :model="tablefrom" label-width="80px">
        <el-form-item label="主题图片">
          <div class="button-block">
            <img :src="img" width="300px" />
            <el-button
              style="position:relative"
              type="primary"
              @click="selectImg"
              >选择图片<label for="selImg"></label
            ></el-button>
            <input type="file" id="selImg" ref="img" @change="selectImg" />
          </div>
        </el-form-item>
        <el-form-item label="主题标题">
          <el-input v-model="title" class="text"></el-input>
        </el-form-item>
        <el-form-item label="主题内容">
          <el-input
            v-model="content"
            class="text"
            type="textarea"
            rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item label="创作时间">
          <el-input v-model="createTime" class="text"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addBlog">立即增加</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { QUERY, UPDATE, DELETE, INSERT } from "@/services/dao.js";
export default {
  data() {
    return {
      id: "",
      img: "",
      title: "",
      content: "",
      createTime: "",
      article: [], //所有文章信息置空
      tablefrom: [],
      // dialogImageUrl: '',
    };
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.add.img = file.url;
      this.dialogVisible = true;
    },
    goBack() {
      this.$router.go(-1);
    },
    // 点击新增主题的确定按钮（确定新增信息） 将所增信息提交给后台
    async addBlog() {
      let data = await INSERT(
        "POST",
        "",
        ' insert_TtTheme(objects: {content:"' +
          this.content +
          '",img: "' +
          this.img +
          '",title: "' +
          this.title +
          '",createTime:"' +
          this.createTime +
          '"}) {     affected_rows   }'
      );
      console.log(data);
      if (data.data.insert_TtTheme.affected_rows > 0) {
        //alert("添加成功!");
        this.$message({
          message: '添加成功!',
          type: 'success'
        });
      }
    },
    selectImg(e) {
      let inputDOM = this.$refs.img;
      if (!inputDOM.files[0]) {
        return;
      }
      if (
        inputDOM.files[0].type == "image/png" ||
        inputDOM.files[0].type == "image/jpeg" ||
        inputDOM.files[0].type == "image/bmp"
      ) {
        this.img = URL.createObjectURL(inputDOM.files[0]);
      } else {
        //alert("格式不正确,请上传图片文件");
        this.$message({
          message: '格式不正确,请上传图片文件',
          type: 'warning'
        });
      }
    },
  },
};
</script>

<style scoped>
.new {
  margin-top: 20px;
}
.text {
  width: 500px;
}
.button-block label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
#selImg {
  display: none;
}
</style>
